




















































import { Vue, Component, Watch } from 'vue-property-decorator'
import AppToolbarComTitulo from '@/components/layout/AppToolbarComTitulo.vue'
import IconesDaToolbarPadroes from '@/components/layout/IconesDaToolbarPadroes.vue'

@Component({
	components: {
		AppToolbarComTitulo,
		IconesDaToolbarPadroes,
	},
})
export default class ToolbarUsuarios extends Vue  {
	pesquisaAtiva = false
	busca = ''

	$refs!: {
		pesquisa: HTMLInputElement & Vue
	}

	@Watch('pesquisaAtiva')
	onChangePesquisaAtiva(ativa: boolean) {
		if (ativa) this.$nextTick(() => this.$refs.pesquisa.focus())
	}

}

